import './PrivacyPolicy.css';
import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="privacy-container">
  <h1>Privacy Policy</h1>
  <p>Effective date: March 27, 2023</p>
  <p>
    My Steve ("us", "we", or "our") operates the My Steve mobile application
    (the "Service"). This page informs you of our policies regarding the
    collection, use, and disclosure of personal data when you use our Service
    and the choices you have associated with that data. We use your data to
    provide and improve the Service. By using the Service, you agree to the
    collection and use of information in accordance with this policy.
  </p>

  <h2>Information Collection and Use</h2>
  <p>
    We collect several different types of information for various purposes to
    provide and improve our Service to you.
  </p>

  <h2>Types of Data Collected</h2>
  <ul>
    <li>
      <strong>Personal Data</strong>
      <p>
        While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you ("Personal Data"). Personally identifiable information may
        include:
      </p>
      <ul>
        <li>Email address</li>
        <li>First name and last name</li>
        <li>Cookies and Usage Data</li>
      </ul>
    </li>
    <li>
      <strong>Usage Data</strong>
      <p>
        When you access the Service with a mobile device, we may collect certain
        information automatically, including, but not limited to, the type of
        mobile device you use, your mobile device unique ID, the IP address of
        your mobile device, your mobile operating system, the type of mobile
        Internet browser you use, unique device identifiers, and other
        diagnostic data ("Usage Data").
      </p>
    </li>
  </ul>

  <h2>Use of Data</h2>
  <p>
    My Steve uses the collected data for various purposes:
  </p>
  <ul>
    <li>To provide and maintain the Service</li>
    <li>To notify you about changes to our Service</li>
    <li>
      To allow you to participate in interactive features of our Service when
      you choose to do so
    </li>
    <li>To provide customer care and support</li>
    <li>
      To provide analysis or valuable information so that we can improve the
      Service
    </li>
    <li>To monitor the usage of the Service</li>
    <li>To detect, prevent and address technical issues</li>
  </ul>

  <h2>Transfer of Data</h2>
  <p>
    Your information, including Personal Data, may be transferred to — and
    maintained on — computers located outside of your state, province, country,
    or other governmental jurisdiction where the data protection laws may
    differ from those in your jurisdiction. Your consent to this Privacy Policy
    followed by your submission of such information represents your agreement
    to that transfer. My Steve will take all steps reasonably necessary to
    ensure that your data is treated securely and in accordance with this
    Privacy Policy and no transfer of your Personal Data will take place to an
    organization or a country unless there are adequate controls in place,
    including the security of your data and other personal information.
  </p>
  <h2>Disclosure of Data</h2>
  <p>
    We may disclose your Personal Data in the good faith belief that such
    action is necessary to:
  </p>
  <ul>
    <li>To comply with a legal obligation</li>
    <li>To protect and defend the rights or property of My Steve</li>
    <li>
      To prevent or investigate possible wrongdoing in connection with the
      Service
    </li>
    <li>
      To protect the personal safety of users of the Service or the public
    </li>
    <li>To protect against legal liability</li>
  </ul>

  <h2>Security of Data</h2>
  <p>
    The security of your data is important to us, but remember that no method
    of transmission over the Internet or method of electronic storage is 100%
    secure. While we strive to use commercially acceptable means to protect
    your Personal Data, we cannot guarantee its absolute security.
  </p>

  <h2>Changes to This Privacy Policy</h2>
  <p>
    We may update our Privacy Policy from time to time. We will notify you of
    any changes by posting the new Privacy Policy on this page. You are advised
    to review this Privacy Policy periodically for any changes. Changes to this
    Privacy Policy are effective when they are posted on this page.
  </p>

  <h2>Contact Us</h2>
  <p>
    If you have any questions about this Privacy Policy, please contact us:
  </p>
  <ul>
    <li>By email: support@mysteve.xyz</li>
    <li>
      By visiting this page on our website:
      <a href="https://mysteve.xyz"> mysteve.xyz</a>
    </li>
  </ul>

  <h2>Your Rights under GDPR (for European Users)</h2>
  <p>
    If you are a resident of the European Union (EU) and European Economic Area
    (EEA), you have certain data protection rights, covered by GDPR. We aim to
    take reasonable steps to allow you to correct, amend, delete, or limit the
    use of your Personal Data. If you wish to be informed about what Personal
    Data, we hold about you and if you want it to be removed from our systems,
    please contact us.
  </p>
  <p>
    In certain circumstances, you have the following data protection rights:
  </p>
  <ul>
    <li>
      The right to access, update, or to delete the information we have on you.
    </li>
    <li>
      The right of rectification. You have the right to have your information
      rectified if that information is inaccurate or incomplete.
    </li>
    <li>
      The right to object. You have the right to object to our processing of
      your Personal Data.
    </li>
    <li>
      The right of restriction. You have the right to request that we restrict
      the processing of your personal information.
    </li>
    <li>
      The right to data portability. You have the right to be provided with a
      copy of the information we have on you in a structured, machine-readable,
      and commonly used format.
    </li>
    <li>
      The right to withdraw consent. You also have the right to withdraw your
      consent at any time where My Steve relied on your consent to process your
      personal information.
    </li>
  </ul>
  <p>Please note that we may ask you to verify your identity before responding to such requests.</p>
  </div>
  );
}

export default PrivacyPolicy;
