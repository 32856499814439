import React, { useState } from 'react';
import logo from './images/App_logo.png';
import './App.css';
import './LandingPage.css';
import Footer from './Footer';

function LandingPage() {
  const [email, setEmail] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch("https://api.sendinblue.com/v3/contacts", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": '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',
        },
        body: JSON.stringify({
          email: email,
          listIds: [4],
          updateEnabled: true,
        }),
      });
  
      if (response.ok) {
        setSubmitSuccess(true);
      } else {
        const text = await response.text();
        console.error("Error submitting email to Sendinblue", text ? JSON.parse(text) : "");
        return;
      }
      const data = await response.json();
      console.log("Email added to the list", data);
    } catch (error) {
      console.error("Error adding email to the list", error);
    }
  };

  return (
    <div className="app">
      <div className="app-content">
        <div className="container">
          <h1 className="heading">Welcome to MySteve!</h1>
          <img src={logo} alt="MySteve Logo" className="logo" />
          <h2 className="subheading">
            Get Productivity Tips Customized To You!
          </h2>

          <div className="features">
            <div className="feature">
              <div className="feature-icon">🚀</div>
              <div className="feature-title">Stay Focused</div>
              <div className="feature-description">
                All your important tasks in one place. Never miss a meeting or event.
              </div>
            </div>
            <div className="feature">
              <div className="feature-icon">🎯</div>
              <div className="feature-title">Set Goals</div>
              <div className="feature-description">
                Create, manage, and achieve your personal and professional goals.
              </div>
            </div>
            <div className="feature">
              <div className="feature-icon">☀️</div>
              <div className="feature-title">Daily Insights</div>
              <div className="feature-description">
                Start every day with a task tailored to you. Become your most productive self.
              </div>
            </div>
          </div>

          <div className="waitlist">
            {!submitSuccess ? (
              <>
                <input
                  type="email"
                  className="email-input"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button
                  className="download-button"
                  onClick={handleSubmit}
                >
                  Join The Waitlist Now
                </button>
              </>
            ) : (
              <div className="success-message">
                <span role="img" aria-label="check-mark" className="check-mark">
                  ✅
                </span>
                Thank you, your email has been saved. We'll reach out to you soon.
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default LandingPage;
