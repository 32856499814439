import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className='footer'>
        <div className="footer-container">
            <div className="footer-content">
                <p>© {new Date().getFullYear()} MySteve. All Rights Reserved.</p>
                <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
        </div>
    </footer>
  );
};

export default Footer;

